import { Store } from "@retailtune/types/lib/DEPRECATED_store";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Position } from "@retailtune/types/lib/geolocation";
import { createPosition, _defaultPosition } from "@retailtune/utils";

// Storelocator API key
export const WOOSMAP_KEY_STORELOCATOR = "woos-463c5225-94bd-3346-9fe8-203c96aa5eac";

// autocomplete
const ADDRESS_AUTOCOMPLETE_URL = "https://api.woosmap.com/address/autocomplete/json";
const ADDRESS_GEOCODE_URL = "https://api.woosmap.com/address/geocode/json";
// geolocation
const GEOLOCATION_URL = "https://api.woosmap.com/geolocation/position/";

export const createWoosmapAddressApiSearchHandler = (key: string, language: string, stores: Store[]) => {
  const uniqueCountryTags = Array.from(new Set(stores.map(s => s.country.tagISO31661Alpha3.toUpperCase())));
  const countryTagsStr = uniqueCountryTags.reduce((acc, current) => `${acc}|country:${current}`, "").slice(1);

  return async (input: string): Promise<PredictionData[]> => {
    const searchParams = new URLSearchParams();
    searchParams.append("key", key);
    searchParams.append("input", input);
    searchParams.append("language", language);
    searchParams.append("components", countryTagsStr);

    const response = await fetch(`${ADDRESS_AUTOCOMPLETE_URL}?${searchParams.toString()}`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`error Woosmap status: ${response.status}`);
    }

    interface Data {
      predictions: { description: string }[];
      status: string;
    }
    const data: Data = await response.json();

    if (data.status.toLowerCase() !== "ok") {
      throw new Error(`[Woosmap Address API] Cannot fetch predictions: status ${data.status}`);
    }
    // ! latitude and longitude are not fetched using this API. These info are fetched
    // ! only after the user interacts with any of the prediction, since it involves
    // ! a call to an additional API (Woosmap Geocode, see below)
    return data.predictions.map(p => ({
      description: p.description,
      latitude: 0,
      longitude: 0,
      layer: "locality",
    }));
  };
};

export async function woosmapApiGeocode(key: string, geocodeString: string): Promise<PredictionData> {
  const searchParams = new URLSearchParams();
  searchParams.append("key", key);
  searchParams.append("address", geocodeString);

  const response = await fetch(`${ADDRESS_GEOCODE_URL}?${searchParams.toString()}`, { method: "GET" });
  if (!response.ok) {
    throw new Error(`Woosmap error: status=${response.status}`);
  }

  const data = await response.json();
  if (data.results.length === 0) {
    throw new Error("Woosmap error: zero results found");
  }

  const result = data.results[0];
  const lat = result.geometry.location.lat;
  const lng = result.geometry.location.lng;

  // translates woosmap prediction types to RetailTune Geolocation API compatible layer values
  const woosmapPredictionTypesToLayersMap: Record<string, "street" | "locality" | "region" | "country"> = {
    route: "street",
    locality: "locality",
    admin_level: "region",
    country: "country",
  };

  const predictiontype = result.types[0] as string;

  return {
    description: result.formatted_address,
    latitude: lat,
    longitude: lng,
    layer: woosmapPredictionTypesToLayersMap[predictiontype] ?? "locality",
  };
}

export async function woosmapIpGeolocation(key: string) {
  const searchParams = new URLSearchParams();
  searchParams.append("key", key);

  const response = await fetch(`${GEOLOCATION_URL}?${searchParams.toString()}`, { method: "GET" });
  if (!response.ok) {
    throw new Error(`Woosmap Geolocation: request rejected with status ${response.status}`);
  }

  const position = await response.json();

  return position;
}

interface DefaultPositionObject {
  countryName: string;
  countryCode: string;
  cityName: string;
  position: Position;
}

export async function getDefaultPositionWithWoosmap(key: string, host: string) {
  try {
    // country_name through IP (promise)
    const approxPositionPromise = woosmapIpGeolocation(key);
    // full country-capital list (promise)
    const countryDataPromise = fetch(`${host}/data/default-positions.json`, { method: "GET" });

    // resolving country_name and country-capital list promises through parallel HTTP requests
    const [approxPosition, countryDataResponse] = await Promise.all([approxPositionPromise, countryDataPromise]);

    if (!countryDataResponse.ok) {
      throw new Error("cannot load default-positions.json");
    }

    const countryData: DefaultPositionObject[] = await countryDataResponse.json();

    // console.log("position through IP: ", approxPosition);

    const countryCode = (approxPosition.country_code as string).toUpperCase();
    // find the record in the list whose name equals the country_name fetched through IP
    let defaultPositionObject = countryData.find(
      (dpo: DefaultPositionObject) => dpo.countryCode.toUpperCase() === countryCode
    );

    if (!defaultPositionObject) {
      // if none is found, use the _default position
      defaultPositionObject = {
        countryName: "_default",
        countryCode: "_default",
        cityName: "_default",
        position: _defaultPosition,
      };
    }

    // console.log("using default position object: ", defaultPositionObject);

    return createPosition({
      ...defaultPositionObject.position,
      // src: PositionType.DEFAULT,
      type: "default",
    });
  } catch (e: any) {
    console.error(e.message);
    // Milan coordinates
    return _defaultPosition;
  }
}
