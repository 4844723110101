import { Service, Store } from "@retailtune/types/lib/DEPRECATED_store";
import {
  AnalyticsCategory,
  AnalyticsAction,
} from "@retailtune/types/lib/analytics";
import { Position } from "@retailtune/types/lib/geolocation";
import { CTAOrigin } from "@retailtune/types/lib/analytics";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Translations } from "../common/translations";
import {
  USER_POSITION_CONSENT,
  fetchUserPosition,
  getDevice,
  getExpirable,
  getRadialDistanceFn,
  positionToLatLngLiteral,
  sortStoresByDistance,
  createPosition,
  createDebounceFn,
  setExpirable,
  createExpirationValue,
  api,
  DEPRECATED__getStoreOpeningTime,
  DEFAULT_POSITION,
  getDistanceText,
} from "@retailtune/utils";
import {
  CustomRenderer,
  getGoogleMapsLink,
  selectMapZoomFromPredictionLayer,
} from "@retailtune/google-maps-utils";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import {
  createAutocomplete,
  createScrollButton,
  createToastMessagesContainer,
} from "@retailtune/vanilla-ui-core";
import { isInViewport } from "../common/utils";
import VanillaJSX from "@retailtune/vanilla-jsx";

import "@retailtune/vanilla-ui-core/styles/autocomplete/Autocomplete.css";
import "@retailtune/vanilla-ui-core/styles/back-to-top/Back-to-top.css";
import "@retailtune/vanilla-ui-core/styles/sidebar/Sidebar.css";
import "@retailtune/vanilla-ui-core/styles/toast/Toast.css";
import "@retailtune/vanilla-ui-core/styles/select/Select.css";
import {
  WOOSMAP_KEY_STORELOCATOR,
  createWoosmapAddressApiSearchHandler,
  getDefaultPositionWithWoosmap,
  woosmapApiGeocode,
} from "../common/woosmap";

interface PositionState {
  defaultPosition: Position;
  userPosition: Position;
  currentPosition: Position;
}

interface StoreFilter {
  services: number[];
}

declare function sendGa4DL(
  category: AnalyticsCategory,
  action: AnalyticsAction,
  label: string,
  storeId: number
): void;
declare const retailtune: {
  rtKey: string;
  language: string;
  locale:string;	
  weekdayNames: string[];
  // server time in milliseconds
  serverTime: number;
  // retailtune domain URL
  assetBaseUrl: string;
  // integration domain URL
  homepageUrl: string;
  translations: Translations;
  storesFilter: StoreFilter;
};

// user device type
let currentDevice = getDevice();
// toast trigger function
let toast: (message: string) => void;
// update the element to listen to for scroll
let updateScrollingElement: (newElement: string | HTMLElement) => void;

let defaultPosition: Position;
let position: PositionState;

let allStores: Store[];
let allServices: Service[];
let clientelaBookingServices: Service[];
let filteredStores: Store[];
const storeMarkersMap = new Map<string, google.maps.Marker>();

let googleMap: google.maps.Map;
let userMarker: google.maps.Marker;
let infoWindow: google.maps.InfoWindow;
let lastClickedMarker: { store: Store } | null;
let currentlySelectedMarker: google.maps.Marker | null;

let markerCluster: MarkerClusterer;
// driving directions related
let directionsDestinationStoreCode: string | null;
let directionsOriginPosition: Position;
let directionsDestinationPosition: Position;
let destinationStoreMarker: google.maps.Marker;
let travelMode: google.maps.TravelMode;
let directionsInstructions: google.maps.DirectionsStep[];
let directionsService: google.maps.DirectionsService;
let directionsRenderer: google.maps.DirectionsRenderer;

// consent related
let userPositionConsent = !!getExpirable<boolean>(USER_POSITION_CONSENT);

// tells whether the user has already been geolocated or not
let positionHasBeenFetched = false;

// # Main function
async function main() {
  api.setOrigin({type: process.env.NODE_ENV as any});

  const { assetHostname } = await api.fetchHostnames(retailtune.rtKey);

  const cachedDefaultPosition = getExpirable<Position | null>(DEFAULT_POSITION);
  const defaultPositionPromise = cachedDefaultPosition
    ? Promise.resolve(cachedDefaultPosition)
    : getDefaultPositionWithWoosmap(WOOSMAP_KEY_STORELOCATOR, assetHostname);

  [defaultPosition, allStores, allServices] = await Promise.all([
    defaultPositionPromise,
    fetchAllStores(),
    fetchAllServices(),
  ]);

  position = {
    defaultPosition,
    userPosition: defaultPosition,
    currentPosition: defaultPosition,
  };

  directionsOriginPosition = defaultPosition;

  clientelaBookingServices = [];
  // filter through the services those related to clientela booking service
  for (let i = 0; i < allServices.length; ++i) {
    if (allServices[i].groupId === 4)
      clientelaBookingServices.push(allServices[i]);
  }

  for (let i = 0; i < allStores.length; ++i) {
    const store = allStores[i];

    // creating and caching the marker for this store
    const marker = new google.maps.Marker({
      position: { lat: store.latitude, lng: store.longitude },
      icon: `${retailtune.assetBaseUrl}/img/pin/pin-store.svg`,
    });

    marker.addListener("click", () => {
      infoWindow.close();
      if (currentlySelectedMarker) {
        currentlySelectedMarker.setIcon(
          `${retailtune.assetBaseUrl}/img/pin/pin-store.svg`
        );
      }
      marker.setIcon(
        `${retailtune.assetBaseUrl}/img/pin/pin-store-selected.svg`
      );
      currentlySelectedMarker = marker;

      infoWindow.setContent(createInfoWindowContent(store));
      infoWindow.open({ anchor: marker, shouldFocus: false });

      if (lastClickedMarker) {
        // user clicked on store card and triggered the callback
        googleMap.setCenter({ lat: store.latitude, lng: store.longitude });
        let newZoom = googleMap.getZoom();
        newZoom = newZoom ? (newZoom > 12 ? newZoom : 12) : 0;
        googleMap.setZoom(newZoom);
      } else {
        lastClickedMarker = { store };
        sendStoreClickEvent(store, "map");
      }
      // simulate bounds changed to execute its handler
      google.maps.event.trigger(googleMap, "bounds_changed");
    });

    storeMarkersMap.set(store.storeCode, marker);
  }

  // # Map initialization
  const mapEl = document.getElementById("rt_map")!;
  const mapCenter = positionToLatLngLiteral(position.currentPosition);

  googleMap = new google.maps.Map(mapEl, {
    zoom: 7,
    center: mapCenter,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
  });

  userMarker = new google.maps.Marker({
    map: googleMap,
    position: mapCenter,
    icon: `${retailtune.assetBaseUrl}/img/pin/pin-user.svg`,
  });

  infoWindow = new google.maps.InfoWindow();
  infoWindow.addListener("closeclick", () => {
    if (currentlySelectedMarker) {
      currentlySelectedMarker.setIcon(
        `${retailtune.assetBaseUrl}/img/pin/pin-store.svg`
      );
      currentlySelectedMarker = null;
    }
  });

  markerCluster = new MarkerClusterer({
    map: googleMap,
    markers: [],
    renderer: new CustomRenderer(
      `${retailtune.assetBaseUrl}/img/pin/cluster.svg`,
      "#252B2E",
      () => {
        if (infoWindow) {
          infoWindow.close();
        }
      }
    ),
  });

  // # Driving Directions initialization
  destinationStoreMarker = new google.maps.Marker({
    map: googleMap,
    visible: false,
    icon: `${retailtune.assetBaseUrl}/img/pin/pin-store-selected.svg`,
  });

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    map: googleMap,
    polylineOptions: {
      strokeColor: "#252B2E",
      strokeWeight: 5,
    },
  });

  // # Travel modes initialization
  const travelModesEl = document.getElementById("rt_travel_modes")!;
  travelModesEl.innerHTML = "";

  const createTravelMode = (mode: google.maps.TravelMode, iconUrl: string) => {
    const travelModeEl = document.createElement("button");
    travelModeEl.id = mode;
    travelModeEl.classList.add("rt-directions-pane__travel-mode");
    if (mode === google.maps.TravelMode.DRIVING) {
      travelModeEl.classList.add("rt-directions-pane__travel-mode--selected");
    }
    const imgEl = document.createElement("img");
    imgEl.src = iconUrl;
    imgEl.alt = mode;
    imgEl.width = 40;
    imgEl.height = 40;

    travelModeEl.onclick = () => {
      document
        .querySelectorAll(".rt-directions-pane__travel-mode")
        .forEach((el) =>
          el.classList.remove("rt-directions-pane__travel-mode--selected")
        );
      travelModeEl.classList.add("rt-directions-pane__travel-mode--selected");
      travelMode = mode;
      updateMapWithDirections();
    };
    travelModeEl.appendChild(imgEl);
    travelModesEl.appendChild(travelModeEl);
  };

  createTravelMode(
    google.maps.TravelMode.DRIVING,
    `${retailtune.assetBaseUrl}/img/icon/driving.svg`
  );
  createTravelMode(
    google.maps.TravelMode.WALKING,
    `${retailtune.assetBaseUrl}/img/icon/walking.svg`
  );
  createTravelMode(
    google.maps.TravelMode.TRANSIT,
    `${retailtune.assetBaseUrl}/img/icon/transit.svg`
  );

  travelMode = google.maps.TravelMode.DRIVING;

  // # Autocomplete initialization

  // * search handler currently using Woosmap Address API
  const searchHandler = createWoosmapAddressApiSearchHandler(
    WOOSMAP_KEY_STORELOCATOR,
    retailtune.language,
    allStores
  );

  // main autocomplete prediction click handler
  const mainPredictionClickHandler = async (
    partialPrediction: PredictionData
  ) => {
    // ? the prediction taken in as parameter is incomplete, since Woosmap Address API
    // ? does not respond with a latitude and a longitude. We use its description to
    // ? ftech these data using the geocode API
    const prediction = await woosmapApiGeocode(
      WOOSMAP_KEY_STORELOCATOR,
      partialPrediction.description
    );

    position.currentPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });

    const latLng = positionToLatLngLiteral(position.currentPosition);
    userMarker.setPosition(latLng);
    googleMap.setCenter(latLng);

    googleMap.setZoom(selectMapZoomFromPredictionLayer(prediction));

    if (infoWindow) {
      infoWindow.close();
    }

    if (directionsDestinationStoreCode) {
      clearDrivingDirections();
      filterStores();
    }

    updateStores();

    sendGa4DL("StoreLocator", "Click", "FreeSearch", 0);
  };

  createAutocomplete({
    anchor: "rt_main_autocomplete",
    searchHandler,
    predictionClickHandler: mainPredictionClickHandler,
    zeroResultsMessage:
      retailtune.translations.k_autocomplete_zero_results_message,
    placeholder: retailtune.translations.k_autocomplete_placeholder,
    searchIcon: {
      path: `${retailtune.assetBaseUrl}/img/icon/arrow.svg`,
      position: "right",
    },
  });

  // directions autocomplete prediction click handler
  const directionsPredictionClickHandler = async (
    partialPrediction: PredictionData
  ) => {
    // ? see 'mainPredictionClickHandler' for info about this
    const prediction = await woosmapApiGeocode(
      WOOSMAP_KEY_STORELOCATOR,
      partialPrediction.description
    );

    directionsOriginPosition = createPosition({
      latitude: prediction.latitude,
      longitude: prediction.longitude,
    });
    updateMapWithDirections();
    sendGa4DL("StoreLocator", "Click", "FreeSearchDirections", 0);
  };

  createAutocomplete({
    anchor: "rt_directions_autocomplete_container",
    searchHandler,
    predictionClickHandler: directionsPredictionClickHandler,
    zeroResultsMessage:
      retailtune.translations.k_autocomplete_zero_results_message,
    placeholder: retailtune.translations.k_autocomplete_placeholder,
  });

  [toast] = createToastMessagesContainer({
    anchor: "rt_storelocator",
    autoClose: 3000,
    position: "top-right",
  });

  [updateScrollingElement] = createScrollButton({
    anchorEl: "rt_stores_area",
    scrollingEl: "rt_stores_area",
  });

  if (currentDevice === "desktop") {
    const params = new URLSearchParams(window.location.search);
    const storeCode = params.get("code");
    directionsDestinationStoreCode = storeCode;
  }

  filterStores();
  showStoresOrDirections();

  showConsentModal(!userPositionConsent);
  if (userPositionConsent) {
    await getUserPosition();
    showStoresOrDirections();
  }

  // # Binding event handlers

  const handleBoundsChange = () => {
    // do nothing if map is showing driving direcitons
    if (directionsDestinationStoreCode) {
      return;
    }

    updateStores();

    if (lastClickedMarker) {
      const { store } = lastClickedMarker;
      lastClickedMarker = null;
      const storeCardEl = document.getElementById(
        `rt_store_card_${store.storeCode}`
      );
      if (
        storeCardEl &&
        currentDevice === "desktop" &&
        !isInViewport(storeCardEl)
      ) {
        storeCardEl.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  const boundsChangedDebounce = createDebounceFn()(500);
  googleMap.addListener("bounds_changed", () =>
    boundsChangedDebounce(handleBoundsChange)
  );

  const btnNearestStoreEl = document.getElementById("rt_nearest_stores_btn")!;
  btnNearestStoreEl.onclick = async () => {
    showConsentModal(!userPositionConsent);
    if (userPositionConsent) {
      infoWindow.close();
      if (directionsDestinationStoreCode) {
        clearDrivingDirections();
        filterStores();
      }
      await getUserPosition();
      updateStores();
    }
    sendGa4DL("StoreLocator", "Click", "FindNearestStore", 0);
  };

  const backToNearestStoreBtn = document.getElementById(
    "back_to_nearest_store_btn"
  );
  if (backToNearestStoreBtn) {
    backToNearestStoreBtn.onclick = () => {
      do {
        const zoom = googleMap.getZoom();
        if (!zoom) break;
        googleMap.setZoom(zoom - 1);
      } while (updateStores() == 0);
    };
  }

  const directionsPaneCloseIcon = document.getElementById(
    "rt_directions_pane_close_btn"
  )!;
  directionsPaneCloseIcon.onclick = () => {
    clearDrivingDirections();
    filterStores();
    updateStores();
  };

  const btnConsentModalClose = document.getElementById("rt_modal_close_btn")!;
  btnConsentModalClose.onclick = () => {
    const consentModalEl = document.getElementById(
      "rt_position_consent_modal"
    )!;
    consentModalEl.classList.remove("rt-modal--visible");
  };

  const btnConsentModalYes = document.getElementById(
    "rt_btn_consent_modal_yes"
  )!;
  btnConsentModalYes.onclick = async () => {
    userPositionConsent = true;
    setExpirable(USER_POSITION_CONSENT, {
      value: JSON.stringify(true),
      expiration: createExpirationValue(1, "years"),
    });

    await getUserPosition();
    showStoresOrDirections();
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Agree", 0);
  };

  const btnConsentModalNo = document.getElementById("rt_btn_consent_modal_no")!;
  btnConsentModalNo.onclick = () => {
    userPositionConsent = false;
    showConsentModal(false);

    sendGa4DL("StoreLocator", "Geo", "Disagree", 0);
  };
}

async function fetchAllStores() {
  const response = await fetch(`${api.origin}/storelocator/v1/stores/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${retailtune.rtKey}`,
    },
    body: JSON.stringify({ language: retailtune.language }),
  });
  if (response.status !== 200)
    throw new Error(`[ERROR] cannot fetch stores (status ${response.status})`);

  return await response.json();
}

async function fetchAllServices() {
  const response = await fetch(`${api.origin}/storelocator/v1/services/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${retailtune.rtKey}`,
    },
    body: JSON.stringify({ language: retailtune.language }),
  });
  if (response.status !== 200)
    throw new Error(
      `[ERROR] cannot fetch services (status ${response.status})`
    );

  return await response.json();
}

async function getUserPosition() {
  const newPosition = await fetchUserPosition(position.defaultPosition);
  // updating position info with the new user position
  position.userPosition = newPosition;
  position.currentPosition = newPosition;
  directionsOriginPosition = newPosition;

  const latLng = positionToLatLngLiteral(position.currentPosition);
  userMarker.setPosition(latLng);
  googleMap.setCenter(latLng);

  if (!positionHasBeenFetched) {
    switch (newPosition.type) {
      case "html5":
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "Geo", "Success", 0)
          : sendGa4DL("StoreLocator", "Geo", "SuccessCookies", 0);
        break;
      case "ip":
        toast(retailtune.translations.k_warning_position_generic_error);
        newPosition.origin === "fetched"
          ? sendGa4DL("StoreLocator", "GeoIP", "Success", 0)
          : sendGa4DL("StoreLocator", "GeoIP", "SuccessCookies", 0);
        break;
      case "default":
        toast(retailtune.translations.k_warning_position_generic_error);
        sendGa4DL("StoreLocator", "GeoDefault", "Success", 0);
        break;
    }

    positionHasBeenFetched = true;
  }
}

function filterStores() {
  // update filteredStores array
  if (retailtune.storesFilter.services.length === 0) {
    filteredStores = new Array<Store>(allStores.length);
    for (let i = 0; i < allStores.length; ++i) {
      filteredStores[i] = allStores[i];
    }
  } else {
    filteredStores = [];
    for (let i = 0; i < allStores.length; ++i) {
      if (
        allStores[i].services.some((serv) =>
          retailtune.storesFilter.services.includes(serv)
        )
      ) {
        filteredStores.push(allStores[i]);
      }
    }
  }

  // update markerCluster markers
  const markers = new Array<google.maps.Marker>(filteredStores.length);
  for (let i = 0; i < filteredStores.length; ++i) {
    markers[i] = storeMarkersMap.get(filteredStores[i].storeCode)!;
  }
  markerCluster.clearMarkers();
  markerCluster.addMarkers(markers);
}

function updateStores(): number {
  const mapBounds = googleMap.getBounds();
  const mapCenter = googleMap.getCenter();

  // make sure map bounds are defined before continuing execution
  if (!(mapBounds && mapCenter)) {
    setTimeout(updateStores, 50);
    return 0;
  }

  const visibleStores: Store[] = [];
  const getDistanceFromUserCurrentPosition = getRadialDistanceFn(
    position.currentPosition.latitude,
    position.currentPosition.longitude
  );

  const latLng = { lat: 0, lng: 0 };
  for (let i = 0; i < filteredStores.length; ++i) {
    // populating store cards
    latLng.lat = filteredStores[i].latitude;
    latLng.lng = filteredStores[i].longitude;
    if (mapBounds.contains(latLng)) {
      filteredStores[i].distance = getDistanceFromUserCurrentPosition(
        latLng.lat,
        latLng.lng
      );
      visibleStores.push(filteredStores[i]);
    }
  }
  visibleStores.sort(sortStoresByDistance);

  const storesEl = document.getElementById("rt_stores")!;
  storesEl.innerHTML = "";
  for (let i = 0; i < visibleStores.length; ++i) {
    storesEl.appendChild(createStoreCard(visibleStores[i]));
  }

  // Update text showing filtered stores number
  const labelStoresCountEl = document.getElementById("rt_stores_count")!;

  labelStoresCountEl.innerHTML =
    visibleStores.length === 1
      ? `<output>1</output><span>${retailtune.translations.k_store_found}</span>`
      : `<output>${visibleStores.length}</output><span>${retailtune.translations.k_stores_found}</span>`;

  const backToNearestStoreBtn = document.getElementById(
    "back_to_nearest_store_btn"
  );
  if (backToNearestStoreBtn) {
    visibleStores.length === 0
      ? backToNearestStoreBtn.classList.add("rt-back-to-nearest-store--visible")
      : backToNearestStoreBtn.classList.remove(
          "rt-back-to-nearest-store--visible"
        );
  }

  return visibleStores.length;
}

function createStoreCard(store: Store) {
  function handleElementClick() {
    const marker = storeMarkersMap.get(store.storeCode);
    if (!marker) return;
    lastClickedMarker = { store };
    google.maps.event.trigger(marker, "click");
    sendStoreClickEvent(store, "list");
  }

  // assuming servertime is already in milliseconds (as it should be)
  const todayTimeMilliseconds = retailtune.serverTime + store.hourOffset * 1000;
  let serverDayIndex = new Date(todayTimeMilliseconds).getDay();
  serverDayIndex = serverDayIndex === 0 ? 6 : serverDayIndex - 1;
  const dayname = retailtune.weekdayNames[serverDayIndex];

  const openingTimeData = DEPRECATED__getStoreOpeningTime({
    serverTime: retailtune.serverTime,
    format: "24h",
    store,
    openingsSeparator: "|",
  });

  const hasBookingRT = store.services.includes(12);
  const clientelaBookingService = clientelaBookingServices.find((s) =>
    store.services.includes(s.id)
  );

  return (
    <li
      id={`rt_store_card_${store.storeCode}`}
      class="rt-store"
      onclick={handleElementClick}
    >
      <article>
        {store.utility === 2 && (
          <span class="rt-next-opening">
            {retailtune.translations.k_next_opening}
          </span>
        )}
        <div class="rt-store__name-container">
          <h2 class="rt-store__name">
            {store.name} - {store.city} - {store.address1}
          </h2>
          {store.distance && (
            <span class="rt-store__distance">
              {getDistanceText(store.distance)}
            </span>
          )}
        </div>
        <div class="rt-store__info">
          <span class="rt-address">
            {store.address1}, {store.city}, {store.country.name}
          </span>
          <div class="rt-contacts-and-hours">
            {store.phone && (
              <div class="rt-phone-container">
                <span>{retailtune.translations.k_contact_informations}:</span>
                <a
                  class="rt-store__phone"
                  href={`tel:${store.phone}`}
                  onclick={() => sendPhoneClickEvent(store, "list")}
                >
                  {store.phone}
                </a>
              </div>
            )}

            {openingTimeData.status === "ok" && (
              <div class="rt-opening-time-container">
                <span>{retailtune.translations.k_store_opening_hours}:</span>
                <span class="rt-current-opening">
                  <span class="rt-dayname">{dayname}:</span>
                  <time class="rt-time">
                    {openingTimeData.isClosed
                      ? retailtune.translations.k_opening_time_closed
                      : openingTimeData.text}
                  </time>
                </span>
              </div>
            )}
          </div>
        </div>
        <div class="rt-store__cta">
          <button
            class="rt-btn rt-btn-primary"
            onclick={() => handleDrivingDirectionsClick(store, "list")}
          >
            {retailtune.translations.k_store_driving_directions}
          </button>

          {store.storeLink && (
            <a
              class="rt-btn rt-btn-secondary"
              href={store.storeLink.replaceAll ("/"+retailtune.language+"/","/"+retailtune.locale+"/")}
              onclick={(e: Event) => {
                e.stopPropagation();
                sendStoreInfoClickEvent(store, "list");
              }}
            >
              {retailtune.translations.k_store_details}
            </a>
          )}

          {/* Check for Retailtune Booking service */}
          {hasBookingRT && (
            <a
              class="rt-btn rt-btn-tertiary"
              href={`${retailtune.homepageUrl}/booking/booking.php?ctaRT=${store.storeCode}-${retailtune.language}&locale=${retailtune.locale}`}
              onclick={(e: Event) => e.stopPropagation()}
            >
              {retailtune.translations.k_book_an_appointment}
              <img
                src={`${retailtune.assetBaseUrl}/img/icon/arrow.svg`}
                alt="arrow icon"
                width="16px"
                height="16px"
              />
            </a>
          )}

          {/* Check for Clientela Booking service */}
          {clientelaBookingService && !hasBookingRT && (
            <a
              class="rt-btn rt-btn-tertiary"
              target="_blank"
              href={clientelaBookingService.description}
              onclick={(e: Event) => e.stopPropagation()}
            >
              {retailtune.translations.k_book_an_appointment}
              <img
                src={`${retailtune.assetBaseUrl}/img/icon/arrow.svg`}
                alt="arrow icon"
                width="16px"
                height="16px"
              />
            </a>
          )}
        </div>
      </article>
    </li>
  );
}

function createInfoWindowContent(store: Store) {

  const hasBookingRT = store.services.includes(12);
  const clientelaBookingService = clientelaBookingServices.find((s) =>
    store.services.includes(s.id)
  );

  return (
    <article class="rt-iw">
      {store.utility === 2 && (
        <span class="rt-next-opening">
          {retailtune.translations.k_next_opening}
        </span>
      )}
      <h2 class="rt-iw__name">
        KIKO MILANO - {store.city} - {store.name}
      </h2>
      <div class="rt-iw__info">
        <span class="rt-address">
          {store.address1}, {store.city}, {store.country.name}
        </span>
        {/* <span class="rt-iw__distance">1,2 km</span> */}
      </div>
      <div class="rt-iw__cta">
        <button
          class="rt-btn rt-btn-primary"
          onclick={() => handleDrivingDirectionsClick(store, "map")}
        >
          {retailtune.translations.k_store_driving_directions}
        </button>

        {store.storeLink && (
          <a
            class="rt-btn rt-btn-secondary"
            href={store.storeLink.replaceAll ("/"+retailtune.language+"/","/"+retailtune.locale+"/")}
            onclick={() => sendStoreInfoClickEvent(store, "map")}
          >
            {retailtune.translations.k_store_details}
          </a>
        )}

        {/* Check for Retailtune Booking service */}
        {hasBookingRT && (
          <a
            class="rt-btn rt-btn-tertiary"
            href={`${retailtune.homepageUrl}/booking/booking.php?ctaRT=${store.storeCode}-${retailtune.language}&locale=${retailtune.locale}`}
            onclick={(e: Event) => e.stopPropagation()}
          >
            {retailtune.translations.k_book_an_appointment}
            <img
              src={`${retailtune.assetBaseUrl}/img/icon/arrow.svg`}
              alt="arrow icon"
              width="16px"
              height="16px"
            />
          </a>
        )}

        {/* Check for Clientela Booking service */}
        {clientelaBookingService && !hasBookingRT && (
          <a
            class="rt-btn rt-btn-tertiary"
            target="_blank"
            href={clientelaBookingService.description}
            onclick={(e: Event) => e.stopPropagation()}
          >
            {retailtune.translations.k_book_an_appointment}
            <img
              src={`${retailtune.assetBaseUrl}/img/icon/arrow.svg`}
              alt="arrow icon"
              width="16px"
              height="16px"
            />
          </a>
        )}
      </div>
    </article>
  );
}

function updateMapWithDirections() {
  const destinationStore = allStores.find(
    (s) => s.storeCode === directionsDestinationStoreCode
  );
  if (!destinationStore) return;

  directionsDestinationPosition = createPosition({
    latitude: destinationStore.latitude,
    longitude: destinationStore.longitude,
  });

  const origin = positionToLatLngLiteral(directionsOriginPosition);
  const destination = positionToLatLngLiteral(directionsDestinationPosition);
  const request: google.maps.DirectionsRequest = {
    origin,
    destination,
    travelMode,
  };

  directionsService.route(request, (result, status) => {
    if (
      !result ||
      status === google.maps.DirectionsStatus.INVALID_REQUEST ||
      status === google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED ||
      status === google.maps.DirectionsStatus.ZERO_RESULTS
    ) {
      return toast(retailtune.translations.k_warning_directions_no_results);
    }
    if (status === google.maps.DirectionsStatus.OK) {
      clearDrivingDirectionsInstructions();
      markerCluster.clearMarkers();
      infoWindow.close();
      showDirectionsPane(true);

      const route = result.routes[0].legs[0];

      directionsInstructions = route.steps;
      directionsRenderer.setOptions({
        directions: result,
        suppressMarkers: true,
      });
      userMarker.setPosition(origin);
      destinationStoreMarker.setPosition(destination);
      destinationStoreMarker.setVisible(true);

      // updating the directions pane UI
      const destinationInputEl = document.getElementById(
        "rt_destination_input_text"
      )!;
      destinationInputEl.textContent = `${destinationStore.name} - ${destinationStore.city}`;

      const destinationLabelTextEl = document.getElementById(
        "rt_destination_label_text"
      )!;
      destinationLabelTextEl.textContent = `${destinationStore.address1}, ${destinationStore.city}`;

      const directionsInstructionsEl = document.getElementById(
        "rt_directions_instructions"
      )!;
      for (let i = 0; i < directionsInstructions.length; ++i) {
        directionsInstructionsEl.appendChild(
          <li class="rt-instruction">
            <div>
              <span>{i + 1}</span>
              <div innerHTML={directionsInstructions[i].instructions}></div>
            </div>
            <span class="rt-time-travel">
              {directionsInstructions[i].duration?.text}
            </span>
          </li>
        );
      }
    }
  });
}

function showStoresOrDirections() {
  if (directionsDestinationStoreCode && currentDevice === "desktop")
    updateMapWithDirections();
  else updateStores();
}

function showConsentModal(shouldShow: boolean) {
  const consentModalEl = document.getElementById("rt_position_consent_modal")!;
  if (shouldShow) {
    consentModalEl.classList.add("rt-modal--visible");
  } else {
    consentModalEl.classList.remove("rt-modal--visible");
  }
}

function showDirectionsPane(shouldShow: boolean) {
  const directionsPaneEl = document.getElementById("rt_directions_pane")!;
  if (shouldShow) {
    directionsPaneEl.classList.add("rt-directions-pane--visible");
    updateScrollingElement("rt_directions_pane");
  } else {
    directionsPaneEl.classList.remove("rt-directions-pane--visible");
    updateScrollingElement("rt_stores_container");
  }
}

function clearDrivingDirectionsInstructions() {
  directionsInstructions = [];
  const directionsInstructionsEl = document.getElementById(
    "rt_directions_instructions"
  )!;
  directionsInstructionsEl.innerHTML = "";
}

function clearDrivingDirections() {
  showDirectionsPane(false);
  directionsDestinationStoreCode = null;

  directionsRenderer.set("directions", null);
  clearDrivingDirectionsInstructions();
  // reset travel mode value and appearance
  travelMode = google.maps.TravelMode.DRIVING;
  document
    .querySelectorAll(".rt-directions-pane__travel-mode")
    .forEach((el) =>
      el.classList.remove("rt-directions-pane__travel-mode--selected")
    );
  document
    .getElementById(google.maps.TravelMode.DRIVING)!
    .classList.add("rt-directions-pane__travel-mode--selected");
  destinationStoreMarker.setVisible(false);
  userMarker.setIcon(`${retailtune.assetBaseUrl}/img/pin/pin-user.svg`);
  userMarker.setPosition(positionToLatLngLiteral(position.currentPosition));
  updateScrollingElement("rt_stores_area");
}

function handleDrivingDirectionsClick(store: Store, origin: CTAOrigin) {
  const eventName =
    origin === "list" ? "DirectionsClickListing" : "DirectionsClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );

  if (currentDevice === "desktop") {
    directionsDestinationStoreCode = store.storeCode;
    updateMapWithDirections();
  } else {
    window.open(getGoogleMapsLink(store), "_blank");
  }
}

function sendStoreClickEvent(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "StoreClickListing" : "StoreClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

function sendPhoneClickEvent(store: Store, origin: CTAOrigin) {
  const eventName = origin === "list" ? "PhoneClickListing" : "PhoneClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

function sendWhatsappClickEvent(store: Store, origin: CTAOrigin) {
  const eventName =
    origin === "list" ? "WhatsappClickListing" : "WhatsappClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

function sendStoreInfoClickEvent(store: Store, origin: CTAOrigin) {
  const eventName =
    origin === "list" ? "DetailsClickListing" : "DetailsClickMap";
  sendGa4DL(
    "Store",
    "Click",
    `${eventName}-${store.city} ${store.address1}-${currentDevice}-${retailtune.language}`,
    store.id
  );
}

window.addEventListener("load", () => {
  // try load the application
  let interval = setInterval(() => {
    try {
      if (!retailtune) {
        throw new Error();
      }
      clearInterval(interval);
      main();
    } catch (e) {}
  }, 50);
});
